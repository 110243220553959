
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React from "react";
import type { NextPageContext } from "next";
import { RRNLRequestError } from "react-relay-network-modern";
import { getAppInitialProps } from "@/components/app/initialProps";
import { ErrorPage, ErrorPageProps } from "@/components/pages/ErrorPage";
import type { GetInitialPageProvidedProps } from "@/containers/AppProviders";
import { getGraphQLResponseErrors } from "@/lib/graphql/error";
const Error = (props: ErrorPageProps) => {
    return <ErrorPage {...props}/>;
};
const getStatusCodeFromError = (error: NextPageContext["err"]): number | undefined => {
    if (!error) {
        return undefined;
    }
    if (error instanceof RRNLRequestError) {
        const graphqlErrors = getGraphQLResponseErrors(error);
        const maybeErrorWithStatus = graphqlErrors.find(r => Boolean(r.status));
        return maybeErrorWithStatus?.status;
    }
    return error.statusCode;
};
Error.getInitialProps = async (ctx: NextPageContext): Promise<GetInitialPageProvidedProps & {
    statusCode: number;
}> => {
    const statusCode = getStatusCodeFromError(ctx.err) ?? ctx.res?.statusCode ?? 404;
    const appInitialProps = await getAppInitialProps(ctx);
    return { ...appInitialProps, statusCode };
};
const __Next_Translate__Page__195d6ddac00__ = Error;

    export default __appWithI18n(__Next_Translate__Page__195d6ddac00__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  